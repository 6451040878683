.footer {
    background-color: #22262a;
    font-family: "Roboto";
}

.footer-box1 {
    width: 100%;
    height: 400px;
}

.footer-box1 iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.footer-box2 {
    padding: 40px 0 15px;
}

.footer-box2-flex {
    gap: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box2-line1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 50px;
}

.box2-line1-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 50px;
}

.box2-line1-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.line1-li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 6px;
}

.logo-i-li {
    width: 50px;
    position: relative;
    height: 50px;
    background-color: #5f676f;
    border-radius: 100%;
}

.logo-i-li i {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: #ffffff;
    font-size: 24px;
}

.line1-li-text {
    display: flex;
    align-items: self-start;
    gap: 5px;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    font-size: 18px;
}

.line1-li-text h1 {
    font-size: 11px;
}

.line1-li-text a {
    font-weight: 700;
    font-size: 11px;
    color: #fff;
    transition: color .4s;
}

.line1-li-text a:hover {
    color: #7f7f7f;
}

.line1-soc {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 5px;
}

.line1-soc a {
    color: #000;
    width: 40px;
    position: relative;
    height: 40px;
    border-radius: 100%;
    background-color: #fff;
}

.line1-soc a i {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    color: #22262a;
    font-size: 22px;
}

.line1-li:nth-child(2)::before,
.line1-li:nth-child(3)::before,
.line1-soc::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    left: -26px;
    background-color: #5f676f;
}

.box2-line2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
}

.box2-line2 span {
    font-size: 16px;
    color: #ffffff77;
}

.box2-line2 span:nth-child(1) i {
    margin-right: 6px;
}