.section1 {
    z-index: 1;
    background: url("https://lh3.googleusercontent.com/fife/ALs6j_GXW5pA-vndnstIgWFVdyaABYb-J29lShVZVM3X0pyRQV2GLT4jurR4cdProk4ihllp8Gx1svaxvo5XhCBMIla-y2HuC7EyUl8oeIQp2f69VMyIwQAj7946pWx2lzXb2KgcgoITDngvfuAbH78KyLb4YX1qYODdzSKcMCwpCi_f6nSoGphsNXV8BGH-jbzbmhD-n1U3D8vDRLo4zJRF40sDRov6REfACzWftKgt45ksEoCU2TvI2m7keWO4NJPiM7kDEihVX5KL-1BKFxa0bvSlvEbfKY9-XARjWxpu6dep8RYF6wCJgeaLrqNRIyHBrddpTXbqW6ckpYRIcfuhlrRO94-PlE1KkhXuXxxBgb4d5EwMDORR9fktaK472-xPo85k71c9Ltut3x6jmGVXlZU0WMbhzd4GsGBZW4WAPCRqP1PW7dzaT62i07wb7V_5AaoF1-1AfKHc2nWhJmvPtB7V8TIrPQ9-8HpVc7H4q92o7hlNonU3wZLl6U-Xqr4aIwL4PnQQzlFaDKA8lg2FdeKNH92EoBi8j4u9nwWqS9PZD_5qPFYBe0uesRW0Ts9_Va9-32FMUorONyykhfhKKZbS1xUcrsmw3FcWwoXl1Qfj1qWmmAof82sccpJyq-u_O59aAM9D350WdIwFrqMgSKpm-KY1PPVOTGUyFxp7tCEFeIcdy7q8T-Moq07eUyCwLpj7gOIwekiB6fBd8tZe_7oybdB9rVgcnrSgwlUkq6KyxWBXV02qtNVxUqzjtKxYI6aytQxAM4CVvcVhQTnEN7vUChM35JIlz2ndudAUf8ur57K1ulCA4xxZtWfyqyEx1LgvdY47KHu4y4CgU4FUFx2of-lruF_Krbv6lnzuzgXfxuFR4_aLTrqwI8kA2hvPTWFil5TfRCaiew5fgXzJJS8h1joyDoO0elCcFh9_snq8NVcs95y76xImXFha7Vxsz85wBiABdpwabFuZ7MIs3x64M5m8oW788jlVVhxXJevvw-QTDkoakkVRmfJEQ04QrkVda6mkVHeKF22jsSr2kYiyQ_6OAQkPClblmXXCPZNhftqg_wRp-kyqsURIxPzME8usbEFEr5gTiOmG2FK1g4ca6ijONyRuuS8tK2sQ1YWfuRTbvT4yhEBSCxYrlRGxw0mCaQyidf4KCNKW4ja51J1xROPaiM-OFHX_LVqOFFgueEJqrEBzfBQdBkjnNIT5F_ycMB_bliLS8iBcI8QfVSrzofl7UAKEo60A6QCCAgz0UE827az28ck7aB6ToTlfPIxmKBqzSKyMkiRCvRfE6uf2NCVpByji9KIsT8VxAxae5-SsS_KSsKYSj2mmLr5HBaPc-hAJ_E4xnEHfSJ3VRG9sRQIQ6X2vY0bTH0_NABDoEbYUVDjpPuha1EjLWwz7_biBHdUfu4b2f9QfavTFN6pYi2v8PmWG1aUCTQBf79dIdXPH395DidkPD-mzhPkQ0z0NzMvrbIxNpVFwQbkZObzOOxPP3P5tz8yZ4mOxxTrBI8T7r--aanDYF-YEfpf9YnOK2yXcFbpTPs0q-2NBvgs-D0GQEKKSuISNn9wlhg5aFfrTjmD9JdTehN-NJ8UkYpP-Dy9RJ51kGtSURSBjN96YGMggc2y9GNhTsv47FmOWfxHoRLCS7ns10pUWWx3UPq2N6xYp1TcqcT3L6pX1dWVKX41tt3vl-Z-twJ8qnAgQ0bQOcNg5lNF_OdmUBw=w1428-h944");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
}

.section2 {
    z-index: 1;
    font-family: "Roboto";
    position: absolute;
    left: 70%;
    top: 80%;
    transform: translate(-50%, -50%);
}

.sec2-box {
    display: flex;
    align-items: start;
    flex-direction: column;
    position: relative;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    height: 100%;
    white-space: nowrap;
}

.sec2-box h3,
.sec2-box h4 {
    font-size: 18px;
}

.sec2-box h2 {
    font-size: 75px;
}

.sec2-btn {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: row;
    gap: 50px;
}

.sec2-button {
    border-radius: 19px;
    font-weight: 400 !important;
    padding: 7px 25px;
    text-transform: uppercase;
    font-size: 18px;
    background-color: #22262a;
    border: none;
    color: white !important;
    cursor: pointer;
    transition: background-color .4s;
}

.sec2-button:hover {
    background-color: #22262aa4;
}

.section3 {
    margin: 100px 0;
}

.sec-3-maps {
    display: grid;
    align-items: self-start;
    justify-content: center;
    grid-template-columns: repeat(3, 350px);
    gap: 20px;
}

.sec-3-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.sec-text-align {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sec-3-text {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px;
    padding: 10px;
    color: white;
    font-family: "Roboto";
    font-size: 23px;
    background-color: #22262a;
}

.sec-3-text h5 {
    z-index: 1;
}

.sec-3-text::after {
    z-index: 0;
    position: absolute;
    content: "";
    left: 39%;
    width: 100%;
    height: 100%;
    background-color: #22262a;
    transform: skew(-50deg, 0deg);
}

.sec-3-box {
    padding: 10px;
    transition: transform .5s, box-shadow .5s;
    margin-bottom: 10px;
    display: flex;
    align-items: self-start;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.sec-3-box:hover {
    transform: scale(1.03);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

.sec-3-box-img {
    width: 100%;
    height: 250px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sec-3-box-img img {
    height: 100%;
}

.sec-3-box-text {
    display: flex;
    align-items: self-start;
    gap: 10px;
    color: #22262a;
    flex-direction: column;
    font-family: "Roboto";
}

.sec-3-box-text h5 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
}

.sec-3-box-text h3 {
    font-size: 15px;
}

.sec-3-box-text h3 span {
    margin-right: 4px;
    font-size: 13px;
    color: #22262a9c;
}

.line-sections {
    width: 100%;
    position: relative;
    display: block;
    height: 2px;
    background-color: #22262a2a;
}

.section4 {
    margin: 100px 0;
}

.sec-4-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sec-4-grid {
    display: grid;
    grid-template-columns: repeat(4, 200px);
    gap: 30px;
    grid-auto-rows: 200px;
}

.sec-4-grid div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}

.sec-4-grid div img {
    cursor: pointer;
    transition: transform .5s;
}

.sec-4-grid div img:hover {
    transform: scale(1.04);
}

.sec-4-grid div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 1;
}

.sec-4-grid div:nth-child(1) img {
    width: 100%;
}

.sec-4-grid div:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
}

.sec-4-grid div:nth-child(2) img {
    height: 100%;
}

.sec-4-grid div:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.sec-4-grid div:nth-child(3) img,
.sec-4-grid div:nth-child(4) img {
    height: 100%;
}

.sec-4-grid div:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.img-open {
    cursor: not-allowed;
    user-select: none;
    display: block;
    opacity: 0;
    transition: opacity .6s, visibility .6s;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.698);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
}

.img-open.active {
    visibility: visible;
    opacity: 1;
}

.img-open span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;
}

.img-open span img {
    cursor: auto;
    max-width: 1200px;
    max-height: 700px;
    position: relative;
}

.btn-close-img {
    padding: 3px;
    font-size: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    right: 0;
    bottom: 100%;
    position: absolute;
    color: white;
    transition: color .3s;
}

.btn-close-img:hover {
    color: rgba(255, 255, 255, 0.381);
}

.section5 {
    position: relative;
    height: 80vh;
    background: url("https://lh3.googleusercontent.com/fife/ALs6j_H3frGa8gWaPexqrI_MagbXU5Na3afPHlLT6FOuSTKbOEwCcq1K_-x1uDf_wONTeQuO1bmjpktulCSdkaPXzTIqgdOqynHbndH_1mh7UVZK-0D5t6Tdj0kJfQUperUMYH0lXTBlYoLUbHtsFdkyaG2ziv1eQCmp1Rbf8uTOku2dVfLVri6tNUMy7kMLXg5pUK8zUfPj8JwSW8D8GcltECTgkK9nZ1CmarXInOFIli5k_6o8tX0X1o8VGfuwMCKrVGhcYzLpFeQKxLYYUX08TS_j5w2KdXRHoB5JiKU0eQeSGR8tWSLvL3bPVhgLlHuHoeNvKxlyShPU0DJpLc2090Wf2s6zTYTOwv7CxbLUpR0KTdo3K8_4mqJPbhXB4P6GA8uhEUesOxI7HjYfQ6Sm5S-B9DseaMlySKX97v89glMxzUzuTqJ3RslvdJEVt37Z1Aj0P8ClioNU6TynL_AESgmENPyoxIZnHY_0Z33hful31-HY6xyL2rpXRnWoSDWcCcI4JF4dXHP7J_StWf4NO68F-NQ9H6urexPwgmTyVBXSQse8CxFmYafUG8pdFymFFsw0ckfyebudZL4TqjjAEZwiwo3XztTU0jLPqCvbBi-LzJv8fLMPl4BwUzdr8_Qt31lninprowbsKIhDyalVjxZBo-gUWi5svF5-hlSwahDCcM2yOlRobj2oOAnCCDbPsApv_hd7epNntJeSzXxJClf9cPfcqaPHTAEHOPGyi5WRYekWv6EDdrX0D_2I342Aji1H3vDIhBpQnJv7Zqx4Y5-Qd75LbDhlTIFZT4-zWwJ21EDVfV_38EHOS1H9CPcbO3xzTSyrNEMqYM-4TTegMy6BNjc3FF591pFhx4qNAlnih8Wimg8jkB89_DIO6ZlcMh7Jl8guUfcf7Bb30ZWaQzz0A2rU2AhcSSj5Ie64pp3RXmiVQAQ78gxhi-EBpmuhBoA2Ok2-gDmRC9Xt66mqzCN0QuQY01QK2I9TWi8lsXznq-WbIC3x26KiTn2ywq1V5uDYlERfUKpklhQKCYKG0QX-KnddWILNRgdkLO_51bGZ8AXPu80izOZuMRWbOxUoPZczIfp_aj6g5ofni-yGwH7ZSPs3pMsZNlNaqQkpxJJnk_-WjCz6ITvAO65DxisLntLD-hliYrBFVv9HPs1LUyoFBG0t8JIYySO63YcaMNhyPKfHVKQCOCDO93KcK9lxN2LrSefldAnugNBFJj4zeGNE9yfTdS_1r5MOXAXUfxc3baf4rQrpdiPrKJ0wGcPDDtjjBisZ1tH_ma4ygukFn1Occ6VU2QPUhQrSqhLkEqE2IQa9N2su14QWl4uTUTrYjbwG2l8Ri1FJr0ti9V6orEpsqXCyS3Tev3fL8aN_EqXtrWFdcI5r7_yonN7KqeytbaN6Df4QqAWhCUFOe2SYMs41ijSBV7tpKxC87H941CbZljXZnkJvLbdcVyDDReJ-O1OElSpQCQw7xRbkPSM2GXlsp6aMe13ljJCm5YOHBQ1M0vseflZ542KkgTcyUa_xJNk2p_Uf_tJBMHQLRv8-a-xIgWmKbHkUO_N835sqjBTpZGJNvddxX4-mWkxLoeRIp9dzDHBHXHOPtmtPutccSfhu8e-Qm_v3Llyhrs9ceCqMxnat6eK8hSMKCB6tZ6-zuNHTj2GWe6EpOVwuDKTRRsiY_IBW-5MsNyQNXO6LXIzUrfZ7vSnbfOYr0slzNg=w1920-h944");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
}

.sec5-box h5 {
    font-family: "Roboto";
    white-space: nowrap;
    font-weight: 900;
    font-size: 50px;
    width: 80%;
}

.section5-text {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 30%;
}