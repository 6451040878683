.request {
    background-color: rgba(0, 0, 0, 0.694);
    position: fixed;
    visibility: hidden;
    z-index: 20;
    opacity: 0;
    transition: opacity .5s, visibility .5s;
    width: 100%;
    height: 100vh;
}

.request.active {
    visibility: visible;
    opacity: 1;
}

.request-form {
    cursor: auto;
    backdrop-filter: blur(10px);
    background-color: #61657573;
    padding: 40px 50px;
    border-radius: 20px;
    min-width: 4%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.offTours,
.offDate,
.offKolvo,
.DpTel {
    display: none;
}

.request-form-btn-close {
    position: absolute;
    right: 1%;
    top: 3%;
    width: 30px;
    height: 30px;
    border: none;
    background: none;
    cursor: pointer;

    i {
        font-size: 30px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform .3s, color .3s;
        transform: translate(-50%, -50%);
    }
}

.request-form-btn-close:hover i {
    color: red;
}

.form1 {
    display: grid;
    display: grid;
    align-items: end;
    justify-content: center;
    grid-template-columns: repeat(2, 350px);
    gap: 20px;

    >button {
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px;
        border: none;
        background-color: #3d496f;
        color: white;
        font-family: "Roboto";
        font-size: 17px;
        transition: background-color .5s;
        cursor: pointer;
    }

    >button:hover {
        background-color: #8a91a98a;
    }
}

.form1 span {
    width: 100%;

    label {
        font-family: "Roboto";
        font-weight: 500;
        font-size: 15px;
        color: #fff;
        display: block;
        margin-left: 13px;
        margin-bottom: 4px;

        span {
            color: rgb(250, 103, 103);
            margin-left: 5px;
        }
    }

    input {
        width: 100%;
        padding: 10px 10px;
        border: none;
        font-size: 15px;
        color: #505977;
        font-family: "Roboto";
        font-weight: 800;
        border-radius: 10px;
    }

    select {
        cursor: pointer;
        width: 100%;
        font-size: 15px;
        padding: 10px 10px;
        color: #505977;
        border: none;
        font-family: "Roboto";
        font-weight: 800;
        border-radius: 10px;
    }
}

.message {
    border-radius: 10px;
    width: 100%;
    display: block;
    resize: vertical;
    box-sizing: border-box;
    font-family: "Roboto";
    font-weight: 800;
    color: #505977;
    padding: 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.164);
    margin-bottom: 20px;
}

.form1 span:nth-child(9) {
    grid-column-start: 1;
    grid-column-end: 3;
}

.form1>button {
    grid-column-start: 1;
    grid-column-end: 3;
}

.text-btn {
    min-width: 300px;
    position: absolute;
    padding: 20px;
    z-index: 10;
    left: 50%;
    top: 50%;
    text-align: center;
    margin-top: 15px;
    border-radius: 20px;
    color: white;
    visibility: hidden;
    font-family: "Roboto";
    font-size: 25px;
    background-color: aliceblue;
    transition: transform .4s, visibility .4s;
    transform: scale(0) translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 0px 15px #000000;

    button {
        padding: 10px 30px;
        border-radius: 15px;
        font-size: 20px;
        cursor: pointer;
        border: none;
        background-color: royalblue;
        color: #fff;
        transition: background-color .5s;
    }

    button:hover {
        background-color: rgba(65, 105, 225, 0.536);

    }
}

.text-btn.required {
    visibility: visible;
    transform: scale(1) translate(-50%, -50%);
    color: green;
}

.text-btn.nonrequired {
    transform: scale(1) translate(-50%, -50%);
    visibility: visible;
    color: red;
}