.about {
    font-family: "Roboto";
}

.about-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about-sec1 {
    background-color: #22262a;
    border-radius: 0 0 50px 50px;
    padding: 10px 5px;
    z-index: 1;
    position: relative;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.about-sec1-1 {
    z-index: 1;
    font-size: 25px;
    font-weight: 400;
}

.about-sec1::after {
    right: -20px;
    z-index: 0;
    content: "";
    position: absolute;
    transform: skew(-30deg, 0deg);
    width: 60%;
    height: 100%;
    background-color: #22262a;
}

.about-sec1::before {
    left: -20px;
    z-index: 0;
    content: "";
    position: absolute;
    transform: skew(30deg, 0deg);
    width: 60%;
    height: 100%;
    background-color: #22262a;
}

.about-sec2 {
    width: 100%;
    margin: 30px 0 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.202);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.about-sec2-1 {
    padding: 30px 20px 30px 70px;
    width: 50%;
    display: flex;
    position: relative;
    align-items: flex-start;
    gap: 30px;
    justify-content: center;
    flex-direction: column;
}

.about-sec2-1-zagolovka {
    color: #22262abb;
    font-size: 30px;
    font-weight: 500;
}

.about-sec2-1-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
    width: 100%;
}

.about-sec2-1-text h2 {
    line-height: 25px;
    white-space: normal;
    font-size: 16px;
    color: #22262a;
    font-weight: 300;
}

.about-sec2-1-result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
}

.about-sec2-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 50%;
    height: 600px;
}

.about-sec2-2 img {
    position: absolute;
    left: -50px;
    top: 0;
    height: 600px;
}

.about-sec2-1-result span {
    padding: 10px;
    border-radius: 20px;
    background-color: #22262abb;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;

    h1 {
        font-size: 27px;
        font-weight: 700;
    }

    h5 {
        font-size: 17px;
        font-weight: 400;
    }
}