@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


*,::after,::before{
    user-select: none;
    margin: 0;
    padding: 0;
    outline: none;
    /* outline: 1px solid red; */
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}
.container{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}
.link,.link:active,.link:hover{    
    color: #000;
    text-decoration: none;
}