.feedbackPage {
    font-family: "Roboto";
    margin: 50px 0;
}

.feedbackPage-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;

    >div {
        width: 100%;
    }
}

.feedbackPage-flex1 {
    h5 {
        font-size: 44px;
        font-weight: 600;
        color: #5f676f;
    }

    h3 {
        margin-top: 16px;
        font-size: 22px;
        font-weight: 400;
        color: #5f676f;
    }
}

.feedbackPage-input {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    label {
        font-size: 18px;
        color: #5f676f;
        font-weight: 500;
    }

    input {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        border: 4px solid #5f676f5d;
        font-size: 17px;
        transition: border-color .5s, color .5s;
    }

    input.active,
    textarea.active {
        border-color: rgba(255, 0, 0, 0.564)
    }

    label.active {
        color: rgba(255, 0, 0, 0.564);
    }

    textarea {
        width: 100%;
        padding: 10px 15px;
        border-radius: 10px;
        border: 4px solid #5f676f5d;
        font-size: 20px;
        transition: border-color .5s, color .5s;
        resize: vertical;
    }
}

.feedbackPage-flex2 {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 30px;

    }
}

.feedbackPage-flex3 {
    margin-top: 50px;
}

.feedbackPage-flex4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row !important;

    label {
        font-size: 16px;
        font-weight: 500;
        color: #5f676f;
    }

    label.active {
        color: rgba(255, 0, 0, 0.564);
    }
}

.feedbackPage-star {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;

    span {
        font-size: 30px;
    }
}

.backPage-star {
    cursor: pointer;
    color: #adb2b8;
}

.backPage-star:hover {
    color: rgb(255, 230, 0);

}

.backPage-star.active {
    color: rgb(255, 230, 0);
}

.feedbackPage-btn {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.feedbtns {
    cursor: pointer;
    background: none;
    border-radius: 20px;
    border: 3px solid #5f676f8f;
    color: #5f676f;
    font-weight: 500;
    padding: 10px 60px;
    font-size: 22px;
    transition: border .4s, color .4s;
}

.feedcancel:hover {
    border-color: red;
    color: red;
}

.feedconfirm:hover {
    border-color: greenyellow;
    color: greenyellow;
}

.feedbtntext {
    visibility: hidden;
    opacity: 0;
    background: none;
    border-radius: 20px;
    border: 3px solid red;
    color: red;
    font-weight: 500;
    padding: 10px 60px;
    font-size: 22px;
    transition: border-color .4s, color .4s, opacity 1s, visibility 1s;
}

.feedbtntext.activeG {
    visibility: visible;
    opacity: 1;
    color: rgb(0, 255, 47);
    border-color: rgb(0, 255, 47);
}

.feedbtntext.activeX {
    visibility: visible;
    opacity: 1;
    color: red;
    border-color: red;
}